
/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@import "assets/themes/Phiatheme/Phiatheme.css";
@import '~ckeditor/contents.css';

body{
    margin: 0;
    height: 100%;
    overflow: auto;
}
a {
    text-decoration: none !important;
  }


  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    overflow-y: scroll;
    box-shadow: inset 0 0 4px #707070;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #0088cead;
    border-radius: 10px;
  }
  
  .hidden-scroll::-webkit-scrollbar {
    display: none !important;
  }

  .vertical-align--top{
    vertical-align: top;
  }